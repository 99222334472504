<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col lg="4" sm="6">
        <v-card>
          <div>
            <v-img class="mx-auto my-15" src="@/assets/tslc.png" width="250" />
          </div>
          <form-login />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormLogin from '@/components/FormLogin'

export default {
  name: 'Login',
  components: {
    FormLogin
  }
}
</script>

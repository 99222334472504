<template>
  <v-form ref="form" @submit.prevent="doLogin">
    <v-text-field v-model="loginCredentials.email" color="secondary" outlined label="Correo" :rules="[rules.email, rules.required]" @focus="loginError = false" />
    <v-text-field
      v-model="loginCredentials.password"
      color="secondary"
      outlined
      label="Contraseña"
      :rules="[rules.required]"
      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
      @focus="loginError = false"
    />
    <div v-if="loginError" class="pa-0 ma-0">
      <p class="error--text my-0 text-center" style="font-size: 13px;">
        Wrong credentials
      </p>
    </div>
    <a>
      <p class="text-end text-forgot">
        Olvidaste tu contraseña?
      </p>
    </a>
    <form-button class="mt-10" type="submit" :loading="loadingButton">
      Iniciar sesión
    </form-button>
  </v-form>
</template>

<script>
import FormButton from '@/components/buttons/FormButton'
import { email, required } from '@/utils/rules'
import { mapActions } from 'vuex'

export default {
  name: 'FormLogin',
  components: {
    FormButton
  },
  data () {
    return {
      loginCredentials: {
        email: '',
        password: ''
      },
      showPassword: false,
      loadingButton: false,
      loginError: false,
      rules: {
        required,
        email
      }

    }
  },
  methods: {
    ...mapActions('auth', ['login', 'fetchMyUserTeacher']),
    async doLogin () {
      if (this.$refs.form.validate()) {
        this.loadingButton = true
        try {
          await this.login(this.loginCredentials)
          await this.fetchMyUserTeacher()
          await this.$router.push({ name: 'Home' })
        } catch (e) {
          this.loginError = true
        } finally {
          this.loadingButton = false
        }
      } else {
        this.loginError = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-application--is-ltr .v-text-field .v-label {
  color: #243674 !important;
  font-weight: bold;
}
.text-forgot {
  color: #253675;

}
</style>
